.App {
  text-align: left;
  font-family: 'Oswald', sans-serif;
  background-color:whitesmoke;
}

#root {
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  padding-left: 4em;
  padding-right: 4em;
}

p, a, body {
  color: rgb(105, 104, 104);
  font-size: 17px;
  line-height: 1.3;
}


.section-head {
  color: black;
  font-weight: 600;
  font-size: 34px;
}

.link {
  color: #0056b3
}

ul {
  list-style: none;
  padding: 0px;
}

ul li:before {
  content: '\2716';
  margin: 0 1em;    /* any design */
}

hr {
  width: 70%;
}

#line-left {
  border-left: 6px solid black;
  height: 500px;
}

#social {
  width: 4.25rem;
  height: 4.25rem;
  font-size: 2.25rem;
  line-height: 3rem;
}

/* -------- NAVIGATION -------- */
.navigation {
  background-color: whitesmoke;
  box-shadow: 2px 2px 6px rgba(151, 151, 151, 0.25);
  height: 70px;
}

/* -------- INTRODUCTION -------- */
.intro-container {
  text-align: left;
}
#intro {
  padding-bottom: 10rem;
  padding-top: 15rem;
}
#intro1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 3px;
  margin-right: 0px;
  /* color: #498500 */
  color: rgb(118, 210, 76);
}

#intro2, #intro3 {
  font-size: calc(50px + (26 - 14) * ((55vw - 300px) / (1600 - 300)));
  font-weight: 600;
  line-height: calc(1.5em + (1.5 - 1.2) * ((55vw - 300px)/(1600 - 300)));;
}

#intro2 {
  color: black;
}

#intro3 {
  color: rgb(105, 104, 104);
}

#blurb {
  font-size: 20px;
  color: rgb(105, 104, 104);
}

/* -------- ABOUT -------- */
.about-container {
  text-align: left;
}

#about {
  padding-top: 10rem;
  padding-bottom: 8rem;
}

#about-image {
  box-sizing: inherit;
  display:  inline-block;
  position: relative;
  margin: 0 auto;
  z-index: 1;
  box-shadow: 8px 8px 10px rgba(88, 88, 88, 0.799);
  border-radius:  50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.image-container {
  position: relative;
  justify-content: center;
}

/* .circle {
  z-index: 1;
  margin-left: 25px;
  margin-top: 10px;
  width: 280px;
  height: 280px;
  background: rgb(118, 210, 76);
  opacity: 45%;
  border-radius: 50%;
  border: 5px solid #0056b3;
} */

.resume-link-container {
  padding-top: 2.5rem;
}

#resume-link-container2 {
  display: inline-flex;
  justify-content: center;
}

#resume-link {
  display: inline-block;
  border: 1.5px solid #0056b3;
  border-radius: 5%;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  line-height: 1;
  padding: 1.25rem 2.75rem;
}

/* -------- SKILLS -------- */
.skills-container {
  text-align: left;
}

#skills {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #0056b3 !important; 
}

/* -------- PROJECTS -------- */
.projects-container {
  text-align: left;
}

#projects {
  padding-top: 15rem;
  padding-bottom: 10rem;
}

#project2 {
  justify-content: flex-end ;
}
.project-contain {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.project-title {
  z-index: 1;
  border-bottom: 2px solid black;
}
#p1title, #p3title{
  text-align: left;
}
#p2title, #p2subtitle {
  text-align: right ;
}

.proj-link {
  display: inline-block;
  border: 1.25px solid #0056b3;
  border-radius: 5%;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 1;
  padding: 1rem 2rem;
}

/* -------- CONTACT -------- */
.contact-container {
  text-align: left;
}

#contact {
  padding-top: 5rem;
  padding-bottom: 1rem;
}

#contact-head, #contact-body {
  text-align: center;
  align-content: center;
}

.page-footer {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 1.75rem;
  font-size: 10px;
  font-weight: 300;
}

#footer2 {
  font-size: 12px;
}

.footer-social {
  justify-content: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
}
#resume-link {
  margin: auto;
}

/* 404 */
.notFound-container {
  padding-bottom: 15rem;
}

.error-container {
  padding-top: 12rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  align-content: center;
}

.notFound {
  padding-left: 5rem;
  padding-right: 5rem;
}

#error-code {
  font-size: 70px;
  font-weight: 900;
}

#intro1return {
  font-size: 20px;
  font-weight: 900;
  color: rgb(118, 210, 76);
}

